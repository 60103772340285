import { get, post, patch, del, put } from "./api.service";

const OperatorService = {
    findCompanies(params) {
        return get("/operator/company/search", params);
    },

    getCompany(id) {
        return get("/operator/company", { id });
    },

    setBillingType(params) {
        return post("/operator/company/billing", params);
    },

    createCompany(params) {
        return post("/operator/company", params);
    },

    updateCompany(params) {
        return patch("/operator/company", params);
    },

    setToDelete(params) {
        return post("/operator/company/deletion", params);
    },

    enableCompany(id) {
        return post("/operator/company/enable", { id });
    },

    suspendCompany(id) {
        return post("/operator/company/suspend", { id });
    },

    findInvoices(params) {
        return get("/operator/billing/search", params);
    },

    findUsers(params) {
        return get("/operator/user/search", params);
    },

    getUser(id) {
        return get("/operator/user", { id });
    },

    createCompanyUser(params) {
        return post("/operator/user", params);
    },

    updateUser(params) {
        return patch("/operator/user", params);
    },

    setRole(params) {
        return post("/operator/user/role", params);
    },
    resetPassword(params) {
        return post("/operator/user/password/reset", params);
    },
    deleteUser(id) {
        return del("/operator/user", { id });
    },
    enableUser(id) {
        return post("/operator/user/enable", { id });
    },

    disableUser(id) {
        return post("/operator/user/disable", { id });
    },

    cloneForm(params) {
        return post("/operator/form/clone", params);
    },

    cloneTraining(params) {
        return post("/operator/training/course/clone", params);
    },

    attachCompany(id) {
        return post("/operator/auth/attach/" + id);
    },

    Updates: {
        getUpdateNote(id) {
            return get("/operator/updates", { id });
        },

        searchUpdateNotes(params) {
            return get("/operator/updates/search", params);
        },

        createUpdateNote(params) {
            return post("/operator/updates", params);
        },

        updateUpdateNote(params) {
            return put("/operator/updates", params);
        },

        deleteUpdateNote(id) {
            return del("/operator/updates", { id });
        },
    },
};

export { OperatorService };
